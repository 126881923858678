































import { Component, Vue } from 'vue-property-decorator';
import SearchBar from '@/components/SearchBar.vue';
import AddButton from '@/components/AddButton.vue';
import BaseTable from '@/components/BaseTable.vue';
import Card from '@/components/Cards/Card.vue';
import { ProgramDTO, ProgramImagesDTO } from '@/data/dto/program.dto';
import ImageSliderCard from '@/views/ProgramsUpload/components/ImageSliderCard.vue';

@Component({
  components: {
    ImageSliderCard,
    BaseTable,
    SearchBar,
    AddButton,
    Card,
  },
})
export default class ProgramsList extends Vue {
  isLoading = true

  isOnError = false

  search = ''

  programs: ProgramDTO[] = []

  columns = [
    'Program',
    'Praças',
    'Ações',
  ]

  async beforeCreate() {
    await this.$store.dispatch('getAllPrograms')
      .then((res: ProgramDTO[]) => {
        this.programs = res;
      })
      .catch(() => {
        this.isOnError = true;
      }).finally(() => {
        this.isLoading = false;
      });
  }

  getMarketsFromImages(images: ProgramImagesDTO[]) {
    return images.map((value: ProgramImagesDTO) => value.marketId.toUpperCase()).join(',');
  }

  updateSearch(search = '') {
    this.search = search;
  }

  programsFilteredBySearch() {
    return this.$searchUtils.searchAll<ProgramDTO>(this.search, this.programs,
      (program) => program.name);
  }
}
